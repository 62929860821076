import React ,{useState,useEffect} from 'react';
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  useDisclosure,
  Spinner,  
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Switch
} from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import CustomModal from '../../../components/CustomModal'
import CustomModalLink from '../../../components/CustomModalLink'
import Create from './Create';
import Edit from './Edit';
import { fetchWrapper } from '../../../helpers';
import Pagination from "react-js-pagination";


function List() {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { onOpen:onOpenEdit, onClose:onCloseEdit } = useDisclosure();
	const [isOpenEdit,setIsOpenEdit] = useState(false)   
	const [modalEditView,setModalEditView] = useState(false)
	const [list,setList] = useState([])
	const [activePage, setActivePage] = useState(1);
  const [listCount, setListCount] = useState(0); 
  const [loading,setLoading] = useState(false);

	const meClose = () => {
		
	   onClose();	   
	}

	useEffect(()=>{
		loadList();
	},[activePage])

	const loadList = async()=>{
		setLoading(true)
		const url = `${process.env.REACT_APP_API_URL}/country/list?page=${activePage}`;
    const data  = await fetchWrapper.get(url)
    setList(data.rows);
    setListCount(data.count)
    setLoading(false)
	}

	const meEdit = (rs) => {
		setIsOpenEdit(true);
		setModalEditView(<Edit  meCloseEdit={meCloseEdit} rs={rs}  loadList={loadList} />)		
	}

	const meCloseEdit = () => {
  		
		   setIsOpenEdit(false);
		   
	}

	const onToggle =async (rs)=>{
		 const value = {
      require_sender_id  :rs.require_sender_id ? 0 : 1,
      id:rs.id
      }

    const url = `${process.env.REACT_APP_API_URL}/country/update`;
    await fetchWrapper.post(url, value);
    loadList()
	}


	const onChangePaginate = (pageNumber) => {
      setActivePage(pageNumber);
      // to set offset
      console.log(pageNumber);
  };

  const UpdatePrice = async()=>{
  	const url = `${process.env.REACT_APP_API_URL}/country/list`;
    const data  = await fetchWrapper.get(url)
    if(data){
    	const url = `${process.env.REACT_APP_API_URL}/country/update-price`;
    	await fetchWrapper.post(url, data);	
    	loadList()
    }
  	

  }


	return(
		<>
			{loading &&
          <HStack width="100%" h="100%" top="0" left="0" bg="rgba(204, 204, 204, 0.5)" position="absolute" zIndex="9999" justifyContent="center">
            <Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.500'
            size='xl'
          />
        </HStack>
      }
		 <CustomModalLink
          showModalButtonText="Country Edit"
          modalHeader="Country Edit"
          isOpenEdit={isOpenEdit}
          onCloseEdit={meCloseEdit}
          onOpenEdit={onOpenEdit}
          
          modalBody={modalEditView}
    />
			<div className="setting_detail_section">

				<HStack justifyContent="space-between">
						<Box>
							<h2 className="main_heading">Countries</h2>
						</Box>
						<Box>
							<HStack>
									<Box>
										<Button className="simple_button" onClick={()=> UpdatePrice()}>Update Country Prices</Button>
									</Box>
									<Box>
										<Button className="simple_button">Download Country List</Button>
									</Box>
									<Box>
										<CustomModal
								            showModalButtonText="Add New Country"
								            modalHeader="Add Country"
								           	isOpen={isOpen}
								           	onClose={onClose}
								           	onOpen={onOpen}
								            modalBody=<Create meClose={meClose} loadList={loadList}  />
							            />
									</Box>
							</HStack>
						</Box>
				</HStack>



				<div className="table_section">
		          <TableContainer width="100%">
		            <Table variant='simple' width="100%" borderWidth="1px" borderColor="#CCCCCC" borderStyle="solid">
		              <Thead >
		                <Tr>
		                  <Th>Country</Th>
		                  <Th>Country Code</Th>
		                  <Th>Sender ID</Th>
		                  <Th>Registered Sender ID Required</Th>
		                   <Th>Channel Used	</Th>
		                 	<Th>SMS Country Price</Th>
		                 	<Th>Whatsapp Country Price</Th>
		                 
		                  <Th></Th>


		                  

		                  
		                </Tr>
		              </Thead>

		              <Tbody fontSize="14px">
		              			{list?.map((rs,index)=>{
		              				return(
		              					<>
		              						<Tr> 
					                            <Td>{rs.name}</Td>
					                            <Td>+{rs.country_code}</Td>
					                            <Td>{rs.sender_id}</Td>
					                            <Td>
					                            		<Switch
																			      isChecked={rs.require_sender_id === 1}
																			      onChange={()=> onToggle(rs)}
																			      
																			      size="lg"
																			      sx={{
																			        'span.chakra-switch__track': {
																			          backgroundColor: '#DDDDDD',
																			        },
																			        'span.chakra-switch__thumb': {
																			          backgroundColor: rs.require_sender_id === 1 ? 'green.400' : 'red.400',
																			        },
																			      }}
																			    />
					                            </Td>	

					                            <Td>{rs?.MsgChannel?.name}</Td>
					                            <Td>{rs.sms_country_price}</Td>
					                            <Td>{rs.whatsapp_country_price}</Td>
					                            
					                            
					                          	<Td style={{color:"#0000EE"}}>
						                            <Menu>
						                              <MenuButton fontSize="14px" >
						                              Action
						                                
						                                  <ChevronDownIcon />
						                              </MenuButton>

						                              <MenuList  style={{minWidth:"20px",border:"1px solid #CCCCCC"}}>
						                                <MenuItem  h="16px" fontSize="12px" onClick={()=> meEdit(rs)}>Edit</MenuItem>
						                                 <MenuDivider />
						                                <MenuItem h="16px" fontSize="12px"  >Editable</MenuItem>
						                                
						                              </MenuList>
						                            </Menu>
				                           		</Td>
					                      
				                            </Tr>
		              					</>
		              				)
		              			})}
				          		
				         

			             
		                           	
			                    
		            
		              </Tbody>
		             
		              
		            </Table>
		            <Pagination
	               activePage={activePage}
	               itemsCountPerPage={100}
	               totalItemsCount={listCount}
	               pageRangeDisplayed={100}
	               onChange={onChangePaginate}
	               innerClass="pagination"
	               itemClass="page-item"
	               activeLinkClass="page-link active"
	               linkClass="page-link"
	               prevPageText="Previous"
	               nextPageText="Next"
	              />
		          </TableContainer>
		        </div>
				
			</div>
		</>
	)
}


export default List
