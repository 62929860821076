import React,{useState,useEffect} from 'react';
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  useDisclosure,
  Spinner,  
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Input 
} from '@chakra-ui/react'
import CustomModal from '../../components/CustomModal'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { fetchWrapper } from '../../helpers';
import moment from 'moment';



function List() {
  const [list , setList]= useState([])
  const [country , setCountry]= useState([])
  const [operator , setOperator]= useState([])
  const [status , setStatus]= useState([])


  const [date , setDate]= useState('')


  const { isOpen, onOpen, onClose } = useDisclosure();
  const { onOpen:onOpenEdit, onClose:onCloseEdit } = useDisclosure();
  const [isOpenEdit,setIsOpenEdit] = useState(false)   
  const [modalEditView,setModalEditView] = useState(false)

  const [loading,setLoading] = useState(false);

  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedOperator, setSelectedOperator] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');

  const meClose = () => {
    
     onClose();    
  }

  useEffect(()=>{
      
    loadlist(
      selectedCountry.id,
      selectedOperator.id,
      selectedStatus.id,
      date
    );

    CountryList();
    OperatorList();
    StatusList();

  },[selectedCountry, selectedOperator,selectedStatus,date])

  const loadlist = async(countryId = "", operatorId = "",status="",date="")=>{
    setLoading(true)
    const formattedDate = date ? moment(date).format('YYYY-MM-DD') : "";
    const url = `${process.env.REACT_APP_API_URL}/msg-log/list?country_id=${countryId}&operator_id=${operatorId}&status=${status}&date=${formattedDate}`;

   

    const data  = await fetchWrapper.get(url)
    setList(data.data);
    setLoading(false)
  }

  const CountryList = async ()=>{
    const url = `${process.env.REACT_APP_API_URL}/country/list`;
    const data  = await fetchWrapper.get(url)
    setCountry(data.rows);
  }

  const OperatorList = async ()=>{
    const url = `${process.env.REACT_APP_API_URL}/operator/list`;
    const data  = await fetchWrapper.get(url)
    setOperator(data.rows);
  }

  const StatusList = async ()=>{
    const url = `${process.env.REACT_APP_API_URL}/status/list`;
    const data  = await fetchWrapper.get(url)
    setStatus(data.data);
  }

  const handleSelect = (type,rs) => {
      const { id, name } = rs;
    if (type === 'country') {
      setSelectedCountry({ id, name });
    } else if (type === 'operator') {
      setSelectedOperator({ id, name });
    }
    else if (type === 'status') {
      setSelectedStatus({ id, name });
    }
  };

  return(
    <>
    {loading &&
        <HStack width="100%" h="100vh" top="0" left="0" bg="rgba(204, 204, 204, 0.5)" position="absolute" zIndex="9999" justifyContent="center">
          <Spinner
          thickness='4px'
          speed='0.65s'
          emptyColor='gray.200'
          color='blue.500'
          size='xl'
        />
      </HStack>
    }
      <div className="body_section">
       
        
        <HStack justifyContent="space-between" mb="25px">
          <Box>
            <Text fontSize="24px" fontWeight="600" color="#000000" my="0px" fontFamily="'Open Sans', sans-serif">Messages Log</Text>
          </Box>
            <Box>
              <HStack>
                  <Box>
                    <Menu>
                      <MenuButton as={Button} rightIcon={<ChevronDownIcon />} className="menu-filter">
                        {selectedCountry.name || "Country"}
                      </MenuButton>
                      <MenuList>
                        <MenuItem onClick={() => handleSelect("country",'')}>All Show</MenuItem>
                        {country?.slice(0,10).map((rs,index)=>{
                          return(
                            <>
                              <MenuItem key={index} onClick={() => handleSelect("country",rs)}>{rs.name}</MenuItem>
                            </>
                          )
                        })}
                      </MenuList>
                    </Menu>
                  </Box>
                  <Box>
                   <Menu>
                      <MenuButton as={Button} rightIcon={<ChevronDownIcon />}  className="menu-filter">
                        {selectedOperator.name || "Operator"}
                      </MenuButton>
                      <MenuList>
                        <MenuItem onClick={() => handleSelect("operator",'')}>All Show</MenuItem>
                        {operator?.slice(0,10).map((rs,index)=>{
                          return(
                            <> 
                              <MenuItem key={index} onClick={() => handleSelect("operator",rs)}>{rs.name}</MenuItem>
                            </>
                          )
                        })}
                        
                        
                      </MenuList>
                    </Menu>
                  </Box>
                  <Box>
                    <Input className="date_range"  type='date' value={date} onChange={(e) => setDate(e.target.value)} />
                  </Box>
                  <Box>
                    <Menu>
                      <MenuButton as={Button} rightIcon={<ChevronDownIcon />}  className="menu-filter">
                        {selectedStatus.name || "Status"}
                      </MenuButton>
                      <MenuList>
                        <MenuItem onClick={() => handleSelect("status",'')}>All Show</MenuItem>
                       {status?.slice(0,10).map((rs,index)=>{
                          return(
                            <> 
                              <MenuItem key={index} onClick={() => handleSelect("status",rs)}>{rs.name}</MenuItem>
                            </>
                          )
                        })}
                        
                      </MenuList>
                    </Menu>
                  </Box>

              </HStack>
            </Box>     
        </HStack>
                           

        <div className="table_section">
          <TableContainer width="100%">
            <Table variant='simple' width="100%" borderWidth="1px" borderColor="#CCCCCC" borderStyle="solid">
              <Thead >
                <Tr>
                  <Th>Message ID</Th>
                  <Th>Customer</Th>
                  <Th>Message Sent To</Th>
                  <Th>Message</Th>
                  <Th>Channel Used</Th>
                  <Th>Cost</Th>
                  <Th>Operator</Th>
                  <Th>Date & Time</Th>
                  <Th>Status</Th>
                  <Th></Th>



                  

                  
                </Tr>
              </Thead>

              <Tbody fontSize="14px">
                  {list.map((rs,index)=>{
                    return(
                      <>
                          <Tr> 
                            <Td>{rs.message_id}</Td>
                            <Td>{rs?.Customer?.business_name}</Td>
                            <Td>+{rs.sent_to}</Td>
                            <Td>{rs.message}</Td>
                            <Td>{rs?.MsgChannel?.name}</Td>
                            <Td>
                                <Text>Buy: {rs.cost}</Text>
                                <Text>Sell: {rs.sale}</Text>
                            </Td>
                            <Td>
                              <Text>{rs?.Operator?.name}</Text>
                              <Text style={{fontSize:'12px',color:"#727272"}}>{rs?.Country?.name}</Text>
                            </Td>

                            <Td>
                              <Text>Received: {moment(rs.receive_date).format('DD/MM/YY | HH:mm')}</Text>
                              <Text>Sent: {moment(rs.sent_date).format('DD/MM/YY | HH:mm')}</Text>
                            </Td>

                            <Td>
                            <div style={{color:rs?.Status?.color}}>{rs?.Status?.msg_status == 0 ? 'Failed' : 'Success'}</div>
                            {rs?.Status?.name}</Td>
                            
                            <Td style={{color:"#0000EE"}}>
                              <Menu>
                                <MenuButton fontSize="14px" >
                                Action
                                  
                                    <ChevronDownIcon />
                                </MenuButton>

                                <MenuList  style={{minWidth:"20px",border:"1px solid #CCCCCC"}}>
                                  <MenuItem  h="16px" fontSize="12px">Edit</MenuItem>
                                   <MenuDivider />
                                  <MenuItem h="16px" fontSize="12px"  >Editable</MenuItem>
                                  
                                </MenuList>
                              </Menu>
                            </Td>
                          </Tr>
                      </>
                    )
                  })}

               
                            
                      
            
              </Tbody>
             
              
            </Table>
            
          </TableContainer>
        </div>
      </div>
    </>
  )
}

export default List;