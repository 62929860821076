import React,{useState,useEffect} from 'react';
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  useDisclosure,
  Spinner,  
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
} from '@chakra-ui/react'
import CustomModal from '../../components/CustomModal'
import CustomModalLink from '../../components/CustomModalLink'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { fetchWrapper } from '../../helpers';
import Create from './Create';
import Edit from './Edit';
import { v4 as uuidv4 } from 'uuid';

function List() {
	const [list , setList]= useState([])
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { onOpen:onOpenEdit, onClose:onCloseEdit } = useDisclosure();
	const [isOpenEdit,setIsOpenEdit] = useState(false)   
	const [modalEditView,setModalEditView] = useState(false)

	const meClose = () => {
		
	   onClose();	   
	}

	useEffect(()=>{
		loadList();
	},[])

	const loadList = async()=>{
		const url = `${process.env.REACT_APP_API_URL}/customer/list`;
    const data  = await fetchWrapper.get(url)
    setList(data.data);
	}

	const meEdit = (rs) => {
		setIsOpenEdit(true);
		setModalEditView(<Edit  meCloseEdit={meCloseEdit} rs={rs}  loadList={loadList} />)		
	}

	const meCloseEdit = () => {
  		
		   setIsOpenEdit(false);
		   
	}

	const GenerateKey = async(rs)=>{
		let  data = {
			api_key:uuidv4(),
			id : rs.id

		}
		  
	       
	    const url = `${process.env.REACT_APP_API_URL}/customer/update`;
  		await fetchWrapper.post(url,data)
  		loadList();

	}


	return(
		<>

		<CustomModalLink
          showModalButtonText="Customer Edit"
          modalHeader="Customer Edit"
          isOpenEdit={isOpenEdit}
          onCloseEdit={meCloseEdit}
          onOpenEdit={onOpenEdit}
          
          modalBody={modalEditView}
    />
			<div className="body_section">
       
        
        <HStack justifyContent="space-between" mb="25px">
          <Box>
            <Text fontSize="24px" fontWeight="600" color="#000000" my="0px" fontFamily="'Open Sans', sans-serif">Customers</Text>
          </Box>
          	<Box>
				<CustomModal
	            showModalButtonText="Add New Customer"
	            modalHeader="Add Customer"
	           	isOpen={isOpen}
	           	onClose={onClose}
	           	onOpen={onOpen}
	            modalBody=<Create meClose={meClose} loadList={loadList} />
	            />
			</Box>     
        </HStack>
                           

        <div className="table_section">
          <TableContainer width="100%">
            <Table variant='simple' width="100%" borderWidth="1px" borderColor="#CCCCCC" borderStyle="solid">
              <Thead >
                <Tr>
                  <Th>Customer Name</Th>
                  <Th>Contact No</Th>
                  <Th>Monthly Message Volume</Th>
                  <Th>Business Name</Th>
                  <Th>Type</Th>
                  <Th>Server IP</Th>
                  <Th>API Key</Th>
                 
                  <Th></Th>


                  

                  
                </Tr>
              </Thead>

              <Tbody fontSize="14px">
              {list.map((rs,index)=>{
              	return(
		          	<>
		          		<Tr> 
	                            <Td style={{color:'#0000EE'}}>{rs.name}</Td>
	                            <Td>{rs.contact_no}</Td>
	                            <Td>{rs?.Volumn?.name}</Td>
	                            <Td>{rs.business_name}</Td>
	                            <Td>{rs?.CustomerType?.name}</Td>
	                            <Td>{rs.server_ip}</Td>
	                            <Td>
															  {rs.api_key && rs.api_key.trim() !== '' ? (
															  	<HStack w="80%" >
															  		<Box>
															  			<Text>{rs.api_key}</Text>
															  			<a href="#" style={{color: '#0000EE'}} onClick={()=> GenerateKey(rs)}>Re-Generate Key</a>
															  		</Box>
															  	</HStack>
															  ) : (
															    <a href="#" style={{color: '#0000EE'}} onClick={()=> GenerateKey(rs)}>Generate Key</a>
															  )}
															</Td>


	                            
	                            
	                          	<Td style={{color:"#0000EE"}}>
		                            <Menu>
		                              <MenuButton fontSize="14px" >
		                              Action
		                                
		                                  <ChevronDownIcon />
		                              </MenuButton>

		                              <MenuList  style={{minWidth:"20px",border:"1px solid #CCCCCC"}}>
		                                <MenuItem  h="16px" fontSize="12px" onClick={()=> meEdit(rs)}>Edit</MenuItem>
		                                 <MenuDivider />
		                                <MenuItem h="16px" fontSize="12px"  >Editable</MenuItem>
		                                
		                              </MenuList>
		                            </Menu>
                           		 </Td>
	                      
                            </Tr>
		          	</>
              	)
              })}

	             
                           	
	                    
            
              </Tbody>
             
              
            </Table>
            
          </TableContainer>
        </div>
      </div>
		</>
	)
}

export default List;