import React,{useState} from 'react';
import { Routes, Route, Link, Outlet,useLocation } from 'react-router-dom';
import Header from '../components/Header'
import { BsArrowLeftSquare, BsShop } from "react-icons/bs";



const Setting = () => {
 	const location = useLocation();
    const [activeItem, setActiveItem] = useState(location.pathname);

    const handleItemClick = (url) => {
        setActiveItem(url);
    };

	return(
		<>
			<Header />

			<div className="body_section">
				<div className="container">
					<div className="setting_page">
						<div className="setting_menu_section">
							<div className="back_menu_section">
								<a href="/dashboard">
									<BsArrowLeftSquare />
									<p>Back to Main Menu</p>
								</a>
							</div>

							<div className="menu_section">
								<ul>
									<li className={activeItem === "/settings" ? "active" : ""}>
										<a href="/settings" onClick={() => handleItemClick("/settings")}>
											<div className="img_box">
												{/*<img src={store_icon} className="icon_img" />
												<img src={store_icon_active} className="icon_img_active" />*/}
											</div>
											<p>Country</p>
										</a>
									</li>

									<li className={activeItem === "/settings/operator" ? "active" : ""}>
										<a href="/settings/operator" onClick={() => handleItemClick("/settings/operator")}>
											<div className="img_box">
												{/*<img src={store_icon} className="icon_img" />
												<img src={store_icon_active} className="icon_img_active" />*/}
											</div>
											<p>Operator</p>
										</a>
									</li>

									<li className={activeItem === "/settings/status" ? "active" : ""}>
										<a href="/settings/status" onClick={() => handleItemClick("/settings/status")}>
											<div className="img_box">
												{/*<img src={store_icon} className="icon_img" />
												<img src={store_icon_active} className="icon_img_active" />*/}
											</div>
											<p>Message Status</p>
										</a>
									</li>

									<li className={activeItem === "/settings/sender" ? "active" : ""}>
										<a href="/settings/sender" onClick={() => handleItemClick("/settings/sender")}>
											<div className="img_box">
												{/*<img src={store_icon} className="icon_img" />
												<img src={store_icon_active} className="icon_img_active" />*/}
											</div>
											<p>Sender ID</p>
										</a>
									</li>

									
								</ul>
							</div>		
						</div>

						<Outlet />
					</div>
				</div>
				
			</div>
			
		</>
	);
}

export default Setting;