import React,{useState,useEffect,useRef} from 'react';
import Pagination from "react-js-pagination";
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  useDisclosure,
  Spinner,  
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
} from '@chakra-ui/react'
import CustomModal from '../../components/CustomModal'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { fetchWrapper } from '../../helpers';
import Papa from 'papaparse'
import { toast } from "react-toastify";
import axios from 'axios';
function List() {

   const inputRef = useRef(null);
  const [list , setList]= useState([])
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { onOpen:onOpenEdit, onClose:onCloseEdit } = useDisclosure();
  const [isOpenEdit,setIsOpenEdit] = useState(false)   
  const [modalEditView,setModalEditView] = useState(false)
  const [activePage, setActivePage] = useState(1);
  const [listCount, setListCount] = useState(0); 
  const [loading,setLoading] = useState(false);


  const [type,setType] = useState('')
  const meClose = () => {
    
     onClose();    
  }

  useEffect(()=>{

    loadList();

  },[activePage])

  const loadList = async()=>{
    setLoading(true)
    const url = `${process.env.REACT_APP_API_URL}/price/list?page=${activePage}`;
    const data = await fetchWrapper.get(url);
    setList(data.rows)
    setListCount(data.count)
    setLoading(false)
  }

   const handleFileChange = (e) => {
  const file = e.target.files[0];

  if (file) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("type", type);

    const url = `${process.env.REACT_APP_API_URL}/csv`;

    axios.post(url, formData)
  .then((response) => {
    console.log(response.data); // Check server response
    toast.success(response.data);
    loadList();
  })
  .catch((error) => {
    console.error(error); // Log error details
    toast.error(`Failed to upload file: ${error.message}`);
  });

    if (inputRef.current) {
      inputRef.current.value = null;
    }
  }
};


  const handleClick = (type) => {
    setType(type);
    inputRef.current.click();
  };

  const onChangePaginate = (pageNumber) => {
      setActivePage(pageNumber);
      // to set offset
      console.log(pageNumber);
  };

  return(
    <>

    {loading &&
          <HStack width="100%" h="100vh" top="0" left="0" bg="rgba(204, 204, 204, 0.5)" position="absolute" zIndex="9999" justifyContent="center">
            <Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.500'
            size='xl'
          />
        </HStack>
      }
    <input type="file" onChange={handleFileChange} accept=".csv"    ref={inputRef} style={{display:'none'}} />
      <div className="body_section">
       
        
        <HStack justifyContent="space-between" mb="25px">
          <Box>
            <Text fontSize="24px" fontWeight="600" color="#000000" my="0px" fontFamily="'Open Sans', sans-serif">Price List</Text>
          </Box>
            <Box>
              <HStack>
                  <Box>
                    <Menu>
                      <MenuButton as={Button} rightIcon={<ChevronDownIcon />} className="menu-filter">
                        Country
                      </MenuButton>
                      <MenuList>
                        <MenuItem>Download</MenuItem>
                        <MenuItem>Create a Copy</MenuItem>
                        
                      </MenuList>
                    </Menu>
                  </Box>
                  <Box>
                   <Menu>
                      <MenuButton as={Button} rightIcon={<ChevronDownIcon />}  className="menu-filter">
                        Operator
                      </MenuButton>
                      <MenuList>
                        <MenuItem>Download</MenuItem>
                        <MenuItem>Create a Copy</MenuItem>
                        
                      </MenuList>
                    </Menu>
                  </Box>
                  <Box>
                    <Menu>
                      <MenuButton as={Button} rightIcon={<ChevronDownIcon />}  className="menu-filter">
                        Download Price List
                      </MenuButton>
                      <MenuList>
                        <MenuItem>Download SMS Rates</MenuItem>
                        <MenuItem>Download WhatsApp Rates</MenuItem>
                        
                      </MenuList>
                    </Menu>
                  </Box>
                  <Box>
                    <Menu>
                      <MenuButton as={Button} rightIcon={<ChevronDownIcon />}  className="menu-filter">
                        Import Updated Price List
                      </MenuButton>
                      <MenuList>
                        <MenuItem onClick={()=> handleClick('sms')}>Import SMS Rates</MenuItem>
                        <MenuItem  onClick={()=> handleClick('whatsapp')}>Import WhatsApp Rates</MenuItem>
                        
                      </MenuList>
                    </Menu>
                  </Box>

              </HStack>
            </Box>     
        </HStack>
                           

        <div className="table_section">
          <TableContainer width="100%">
            <Table variant='simple' width="100%" borderWidth="1px" borderColor="#CCCCCC" borderStyle="solid">
              <Thead >
                <Tr>
                  <Th>Country</Th>
                  <Th>Operator</Th>
                  <Th>MCC</Th>
                  <Th>MNC</Th>
                  <Th>SMS Cost</Th>
                  <Th>WhatsApp Cost</Th>
                  <Th>Last Updated</Th>
                 
                  <Th></Th>


                  

                  
                </Tr>
              </Thead>

              <Tbody fontSize="14px">

                  {list.map((rs,index)=>{
                    return(
                      <>
                        <Tr key={index}> 
                          <Td>{rs?.Operator?.Country?.name}</Td>
                          <Td>{rs?.Operator?.name}</Td>
                          <Td>{rs?.Operator?.mcc}</Td>
                          <Td>{rs?.Operator?.mnc}</Td>
                          <Td>{rs.sms_cost}</Td>
                          <Td>{rs.whatsapp_cost}</Td>
                          <Td>27/08/2024</Td>
                          
                          <Td style={{color:"#0000EE"}}>
                            <Menu>
                              <MenuButton fontSize="14px" >
                              Action
                                
                                  <ChevronDownIcon />
                              </MenuButton>

                              <MenuList  style={{minWidth:"20px",border:"1px solid #CCCCCC"}}>
                                <MenuItem  h="16px" fontSize="12px">Edit</MenuItem>
                                 <MenuDivider />
                                <MenuItem h="16px" fontSize="12px"  >Editable</MenuItem>
                                
                              </MenuList>
                            </Menu>
                           </Td>
                    
                        </Tr>
                      </>
                    )
                  })}
              
                  


               
                            
                      
            
              </Tbody>
             
              
            </Table>

            <Pagination
               activePage={activePage}
               itemsCountPerPage={100}
               totalItemsCount={listCount}
               pageRangeDisplayed={100}
               onChange={onChangePaginate}
               innerClass="pagination"
               itemClass="page-item"
               activeLinkClass="page-link active"
               linkClass="page-link"
               prevPageText="Previous"
               nextPageText="Next"
              />
            
          </TableContainer>
        </div>
      </div>
    </>
  )
}

export default List;