import React ,{useEffect,useState} from 'react';
import * as Yup from 'yup';
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  useDisclosure,
  Spinner,  
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
} from '@chakra-ui/react'
import {InputText,Form,SelectFieldSearch,SelectField,MultiSelectField} from '../../components/form'
import { toast } from "react-toastify";
import { fetchWrapper } from '../../helpers';




function Create ({meCloseEdit,loadList,rs}){
	const [TypeList,SetTypeList] = useState([]);
	const [Volumn,SetVolumnList] = useState([]);

	const [formData, setFormData] = useState({
    name: rs.name,
    contact_no: rs.contact_no,
    business_name: rs.business_name,
    customer_type_id: rs.customer_type_id,
    volumn_id: rs.volumn_id,
    server_ip: rs.server_ip,  
    id:rs.id
    
    
    
  });

  const FormSchema = Yup.object().shape({
	   name: Yup.string().required('Required'),
	   contact_no: Yup.string().required('Required'),
	   business_name: Yup.string().required('Required'),
  	customer_type_id: Yup.string().required('Required'),
   	volumn_id: Yup.string().required('Required'),
   	server_ip: Yup.string().required('Required'),
	 	
	});

  

	useEffect(() => {
        LoadTypeList()
       LoadVolumnList()
       
        
    }, []);

  	const LoadTypeList = async () => {
	      const url = `${process.env.REACT_APP_API_URL}/customer-type/list`;
	      const data  = await fetchWrapper.get(url)
	      // console.log(data)
	      SetTypeList(data)
  	}

  	const LoadVolumnList = async () => {
      const url = `${process.env.REACT_APP_API_URL}/volumn/list`;
      const data  = await fetchWrapper.get(url)
      // console.log(data)
      SetVolumnList(data)
  	}

  


	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		   let  data = values
		  
	       
	    const url = `${process.env.REACT_APP_API_URL}/customer/update`;
  		await fetchWrapper.post(url,data)
 		loadList()
  		meCloseEdit();
  		toast(`Record has been update `, {
	       type: "info"
	    });		
	}

	return(
		<>
			
				<Form
		            enableReinitialize
		            validationSchema={FormSchema}
		            initialValues={formData}
		            onSubmit={onSubmit}
				>

					<Box mb="10px">
						<InputText name="name" title="Customer Name" placeholder="Please Enter Customer Name"  />
					</Box>
					<Box mb="10px">
						<InputText name="contact_no" title="Contact No" placeholder="Please Enter Contact Number" />
					</Box>
					<Box mb="10px">
						<InputText name="business_name" title="Business Name" placeholder="Please Enter Business Name"  />
					</Box>

					<HStack w="100%" mb="30px">
						<Box w="30%">
							<SelectField name="customer_type_id" list={TypeList} title="Type"/>		
						</Box>
						<Box w="70%">
							<SelectField name="volumn_id" list={Volumn} title="Expected Monthly Messages Volume"/>
						</Box>
					</HStack>

					<Box mb="10px">
						<InputText name="server_ip" title="Server IP" placeholder="Please Enter 127.0.0.1"  />
					</Box>
				 					 
				 	
				 	
				 	
			         <Button py="10px" px="25px" color="#fff" bg="#2BB673" fontSize="14px" fontWeight="500" fontFamily="'Lato', sans-serif"
					            mb={4}
					            
					            type='submit'
			          		>
			           		Update Customer
			          		</Button>
				</Form>

		</>
	)
}

export default Create;